
#fromemail, #subjectemail{display:none;}
a#email{
display:none;
color:#333;;
text-decoration:none;
font-size:13px;}

.centered{text-align: center;}

.centeredline {
    font-size: 4px;
    margin: 0 0 1px;
    text-align: center;
    font-stretch: extra-condensed;
}

.formeInput{border-width: 1px;
    border-style: inset;
    border-color: #292f33;
}

#email {display:none;}
 #poemoff{display:none;}
//.line{display:none;}

.titleline{font-size:28px;}
.em0{margin-left:0em; text-indent:0px !important;}
.em1{margin-left:1em; text-indent:0px !important;}
.em2{margin-left:2em; text-indent:0px !important;}
.em3{margin-left:3em; text-indent:0px !important;}
.em4{margin-left:4em; text-indent:0px !important;}
.em5{margin-left:5em; text-indent:0px !important;}
.em6{margin-left:6em; text-indent:0px !important;}
.em7{margin-left:7em; text-indent:0px !important;}
.em8{margin-left:8em; text-indent:0px !important;}
.em9{margin-left:9em; text-indent:0px !important;}
.em10{margin-left:10em; text-indent:0px !important;}
.em11{margin-left:11em; text-indent:0px !important;}
.em12{margin-left:12em; text-indent:0px !important;}
.em13{margin-left:13em; text-indent:0px !important;}
.em14{margin-left:14em; text-indent:0px !important;}
.em15{margin-left:15em; text-indent:0px !important;}
.em16{margin-left:16em; text-indent:0px !important;}
.em17{margin-left:17em; text-indent:0px !important;}
.em18{margin-left:18em; text-indent:0px !important;}
.em19{margin-left:19em; text-indent:0px !important;}
.em20{margin-left:20em; text-indent:0px !important;}
.em21{margin-left:21em; text-indent:0px !important;}
.em22{margin-left:22em; text-indent:0px !important;}
.em23{margin-left:23em; text-indent:0px !important;}
.em24{margin-left:24em; text-indent:0px !important;}
.em25{margin-left:25em; text-indent:0px !important;}
.em26{margin-left:26em; text-indent:0px !important;}
.em27{margin-left:27em; text-indent:0px !important;}
.em28{margin-left:28em; text-indent:0px !important;}
.em29{margin-left:29em; text-indent:0px !important;}
.em30{margin-left:30em; text-indent:0px !important;}
