// Default black theme

// Color variables
$black: #181818;
$red: #E74727;
$red-small: #DC3918;
$white: #fff;

// Config
$color-background: $black !default;
$color-dot-accent: $red !default;
$color-foreground: $white !default;
$color-title: $white !default;
$color-body-text: $black !default;
$color-text-accent: $red !default;
$color-code: $red-small !default;
$color-nav-link: $red !default;
$color-primary-link: $red !default;

.font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Import sass partials (used in all themes)
@import
        "variables",
        "base",
        "components",
        "utilities"
;
