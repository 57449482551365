// Styling markdown output

// Code formatting
pre {
    border-left: $spacer-1 solid $color-border;
    margin: $spacer-3 0;
    padding-left: 16px;
    width: (9 / 12 * 100%); // matches col-9
    overflow-x: auto;
}

code {
    color: $color-code;
    font-size: $body-font-size;
    padding: 1px 0px;
}

//input

// lines and font stuff

.line{line-height:30px;
  text-indent: -32px;
  margin: 0 0 0 32px;
  padding-right: 16px;}
.prose {

  a {
    text-decoration: underline dotted;

    &:hover {
      text-decoration: underline;
    }
  }


.metadata{font-size: larger;}

  p, ol, ul {
    font-size: $prose-font-size;
    width: 100%;

    @media (min-width: $breakpoint-lg) {
      width: (10 / 12 * 100%); // matches col-10
    }

  }



  ul, ol {
    padding-left: 40px;
  }

  li {
    margin-bottom: 0.5em;

    ul li, ol li {
      margin-bottom: 0;
    }
  }

  img {

    max-width: 100%;

    @media (min-width: $breakpoint-lg) {
      max-width: (12 / 10 * 100%); // make image fill width of container on desktop
    }

  }

  blockquote {
    line-height: 1.375;
    padding-left: 20px;
    margin: 40px 0 40px -16px;
    border-left: $spacer-1 solid $color-border;
    font-style: italic;

    p {
      font-size: 24px;
    }

    @media (min-width: $breakpoint-lg) {
      padding-left: $spacer-3;
      margin: $spacer-4 0 $spacer-4 -40px;
      max-width: (11 / 10 * 100%);

      p {
        font-size: 32px;
      }

    }

  }

  hr {
    color: $color-body-text;
    border-style: solid;
    border-width: thin;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  dt {
    font-weight: bold;
    font-style: italic;
    line-height: 1.25;
  }

  dd {
    font-style: italic;
    margin-bottom: 0.5em;
  }

  // Markdown tables
  table {
    border-collapse: collapse;
    display: block;
    width: 100%;
    margin-bottom: 1.5em;
    overflow: auto;
    // For Firefox to horizontally scroll wider tables.
    word-break: normal;
    word-break: keep-all;

    th {
      font-weight: bold;
      text-align: left;
    }

    th,
    td {
      padding: $spacer-2 $spacer-3 $spacer-2 2px;
      border-top: 1px solid $color-body-text;
      border-bottom: 1px solid $color-body-text;
    }

    tr {
      background-color: #fff;
      border-top: 1px solid $color-body-text;
    }

    tr th {
      border-top: 2px solid $color-body-text;
      border-bottom: 2px solid $color-body-text;
    }
  }

}

.homepage-input{
  border: #f12e07e8 1.5px solid;
    padding: 10px;
    border-radius: 3px;
}


.display-forme-rect{fill:white;}
.display-forme-link:hover rect, .chart:hover rect{fill:red;}
.metadata a {

text-decoration: underline;
}


.forme-button,.forme-button-home {
  border-radius: 3px;
  border: 4px red double;
  display: inline-block;
  cursor: pointer;
  color:inherit;
  font-family: Georgia;
  font-size: 17px;
  padding: 11px 23px;
  text-decoration: none;}

.forme-button-home:hover {
background-color: black;
text-decoration: none;
}


.forme-search-button {
  border-radius: 3px;
  border: 4px white double;
  display: inline-block;
  cursor: pointer;
  background:#E74727;
  color:white;
  font-family: Georgia;
  font-size: 17px;
  padding: 11px 23px;
  text-decoration: none;}

.forme-search-button:hover {
background-color:#c70707;
}
.forme-button:active {
	position:relative;
	top:1px;
}


  /* Sidebar */
  /*
   * Sidebar
   *
   * The sidebar is the drawer, the item we are toggling with our handy hamburger
   * button in the corner of the page.
   *
   * This particular sidebar implementation was inspired by Chris Coyier's
   * "Offcanvas Menu with CSS Target" article, and the checkbox variation from the
   * comments by a reader. It modifies both implementations to continue using the
   * checkbox (no change in URL means no polluted browser history), but this uses
   * `position` for the menu to avoid some potential content reflow issues.
   *
   * Source: http://css-tricks.com/off-canvas-menu-with-css-target/#comment-207504
   */
  
  /* Style and "hide" the sidebar */
  .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    right: -18rem;
    width: 18rem;
    visibility: hidden;
    overflow-y: auto;
    font-family: "PT Sans", Helvetica, Arial, sans-serif;
    font-size: .875rem; /* 15px */
    color: rgba(255,255,255,.6);
    background-color: #202020;
    -webkit-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;
  }
  @media (min-width: 30em) {
    .sidebar {
      font-size: 1.15rem; /* 14px */
      right: -24rem;
      width: 24rem;
    }
  }
  
  /* Sidebar content */
  .sidebar a {
    font-weight: normal;
    color: #fff;
  }
  .sidebar-item {
    padding: 1rem;
  }
  .sidebar-item p:last-child {
    margin-bottom: 0;
  }
  
  /* Sidebar nav */
  .sidebar-nav {
    border-bottom: 1px solid rgba(255,255,255,.1);
  }
  .sidebar-nav-item {
    display: block;
    padding: .5rem 1rem;
    border-top: 1px solid rgba(255,255,255,.1);
    font-size:20px;
  }

  .sidebar-nav-item.active,
  a.sidebar-nav-item:hover,
  a.sidebar-nav-item:focus {
    text-decoration: none;
    background-color: rgba(255,255,255,.1);
    border-color: transparent;
  }
  
  @media (min-width: 48em) {
    .sidebar-item {
      padding: 1.45rem;
    }
    .sidebar-nav-item {
      padding-left:  2.25rem;
      padding-right: 1.25rem;
    }
  }
  
  /* Hide the sidebar checkbox that we toggle with `.sidebar-toggle` */
  .sidebar-checkbox {
    position: absolute;
    opacity: 0;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
  }
  
  
  /* Style the `label` that we use to target the `.sidebar-checkbox` */
  .sidebar-toggle {
    position: absolute;
    top:  2rem;
    right: 2rem;
    display: block;
    padding: .25rem .75rem;
    color: #505050;
    background-color:transparent;
    border-radius: .25rem;
    cursor: pointer;
  }
  
  .sidebar-toggle:before {
    display: inline-block;
    width: 2.5rem;
    height: 2.15rem;
    content: "";
    background-image: -webkit-linear-gradient(to bottom, #555, #555 20%, transparent  20%, transparent 40%, #555 40%, #555 60%, transparent  60%, transparent  80%, #555 80%, #555 100%);
    background-image:    -moz-linear-gradient(to bottom, #555, #555 20%, transparent  20%, transparent 40%, #555 40%, #555 60%, transparent  60%, transparent  80%, #555 80%, #555 100%);
    background-image:     -ms-linear-gradient(to bottom, #555, #555 20%, transparent  20%, transparent 40%, #555 40%, #555 60%, transparent  60%, transparent  80%, #555 80%, #555 100%);
    background-image:         linear-gradient(to bottom, #555, #555 20%, transparent  20%, transparent 40%, #555 40%, #555 60%, transparent  60%, transparent  80%, #555 80%, #555 100%);
  }
  
 
  #sidebar-checkbox:checked ~ .sidebar-toggle {
    color: #fff;
    background-color: #555;
    padding-top:8px;
  }
  

  #sidebar-checkbox:checked ~ .sidebar-toggle:before {
    background-image: -webkit-linear-gradient(to bottom, #fff, #fff 20%, #555 20%, #555 40%, #fff 40%, #fff 60%, #555 60%, #555 80%, #fff 80%, #fff 100%);
    background-image:    -moz-linear-gradient(to bottom, #fff, #fff 20%, #555 20%, #555 40%, #fff 40%, #fff 60%, #555 60%, #555 80%, #fff 80%, #fff 100%);
    background-image:     -ms-linear-gradient(to bottom, #fff, #fff 20%, #555 20%, #555 40%, #fff 40%, #fff 60%, #555 60%, #555 80%, #fff 80%, #fff 100%);
    background-image:         linear-gradient(to bottom, #fff, #fff 20%, #555 20%, #555 40%, #fff 40%, #fff 60%, #555 60%, #555 80%, #fff 80%, #fff 100%);
  }
  
  @media (min-width: 30.1em) {
    .sidebar-toggle {
      position: fixed;
    }
  }
  
  @media print {
    .sidebar-toggle {
      display: none;
    }
  }
  
  /* Slide effect
   *
   * Handle the sliding effects of the sidebar and content in one spot, seperate
   * from the default styles.
   *
   * As an a heads up, we don't use `transform: translate3d()` here because when
   * mixed with `position: fixed;` for the sidebar toggle, it creates a new
   * containing block. Put simply, the fixed sidebar toggle behaves like
   * `position: absolute;` when transformed.
   *
   * Read more about it at http://meyerweb.com/eric/thoughts/2011/09/12/.
   */
  
  .wrap,
  .sidebar,
  .sidebar-toggle {
    -webkit-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
            backface-visibility: hidden;
  }
  .wrap,
  .sidebar-toggle {
    -webkit-transition: -webkit-transform .3s ease-in-out;
            transition: transform .3s ease-in-out;
  }
  
  #sidebar-checkbox:checked + .sidebar {
    z-index: 10;
    visibility: visible;
  }
  #sidebar-checkbox:checked ~ .sidebar,
  #sidebar-checkbox:checked ~ .wrap,
  #sidebar-checkbox:checked ~ .sidebar-toggle {
    -webkit-transform: translateX(-18rem);
        -ms-transform: translateX(-18rem);
            transform: translateX(-18rem);
  }
  
  @media (min-width: 30em) {
    #sidebar-checkbox:checked ~ .sidebar,
    #sidebar-checkbox:checked ~ .wrap,
    #sidebar-checkbox:checked ~ .sidebar-toggle {
      -webkit-transform: translateX(-24rem);
          -ms-transform: translateX(-24rem);
              transform: translateX(-24rem);
    }
  }